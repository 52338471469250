<template>
    <b-modal id="modal-image" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <vue-dropzone
                        id="dropzone"
                        ref="myVueDropzone"
                        :use-custom-slot="true"
                        :options="dropzoneOptions"
                        v-on:vdropzone-sending="sendingEvent"
                        v-on:vdropzone-success="onUploadSuccess"
                    >
                    <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Glisser-déposer des fichiers ou cliquer pour parcourir.</h4>
                    </div>
                    </vue-dropzone>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import vue2Dropzone from "vue2-dropzone";
    import TokenService from '../services/token.service';

    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        name: 'SellImage',
        data() {
            return {
                submitted: false,
                modalTitle: 'Ajouter des images',
                dropzoneOptions: {
                    url: process.env.VUE_APP_API_URL + '/sell_images',
                    thumbnailWidth: 150,
                    acceptedFiles: 'image/*,.jpg,.jpeg,.png',
                    dictFileTooBig: "Fichier trop lourd ({{filesize}}MiB). Poids max : {{maxFilesize}}MiB.",
                    dictInvalidFileType: "Seuls les fichiers de type image sont acceptés",
                    dictMaxFilesExceeded: "Pas plus de 5 photos par vente ne sont acceptés",
                    maxFilesize: 0.5,
                    maxFiles: 5,
                    headers: {
                        'Cache-Control': null,
                        'X-Requested-With': null,
                        'Authorization': 'Bearer ' + TokenService.getLocalAccessToken()
                    }
                },
                imgBaseUrl: process.env.VUE_APP_IMG_URL,
            }
        },
        components: {
            vueDropzone: vue2Dropzone,
        },
        props: ['sell'],
        methods: {
            sendingEvent(file, xhr, formData) {
                formData.append('sell', '/api/sales/' + this.sell.id);
            },
            onUploadSuccess() {
                this.$emit('photos-added');
            }
        }
    }
</script>