<template>
  <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <button
          class="btn btn-primary me-2"
          v-b-modal.modal-image
        >
          <i class="mdi mdi-cloud-upload me-2"></i> Photos
        </button>
        <SellImage @photos-added="onSellUpdated" :sell="sell" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="invoice-title col-9">
                <div class="text-muted">
                  <img class="logo" v-if="sellLoaded" :src="'/logos/' + sell.company.prefix + '.png'">
                  <p v-if="!sellLoaded" class="mb-1 placeholder-glow">
                    <span class="placeholder col-4"></span><br>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                  </p>
                </div>
              </div>
            </div>

            <hr class="my-4" />

            <div class="row">
              <div class="col-sm-6">
                <div class="text-muted" v-if="sellLoaded">
                  <h5 class="font-size-15 mb-2">{{ sell.customer.firstname }} {{ sell.customer.lastname }}</h5>
                  <p class="mb-1">{{ sell.customer.address }}, {{ sell.customer.postcode }} {{ sell.customer.city }}</p>
                  <p class="mb-1">{{ sell.customer.email }}</p>
                  <p class="mb-1">{{ (sell.customer.phone != '') ? sell.customer.phone : sell.customer.mobile }}</p>
                  <p class="mb-1"><strong>Date des travaux au plus tard</strong> : {{ SellService.getDate(sell.workDate) }}</p>
                  <h5 class="font-size-14 mt-0"></h5>
                </div>
                <div class="text-muted" v-if="!sellLoaded">
                  <h5 class="placeholder-glow"><span class="placeholder col-6"></span></h5>
                  <p class="mb-1 placeholder-glow"><span class="placeholder col-4"></span></p>
                  <p class="mb-1 placeholder-glow"><span class="placeholder col-4"></span></p>
                  <p class="placeholder-glow"><span class="placeholder col-4"></span></p>
                  <p class="placeholder-glow"><span class="placeholder col-4"></span></p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="text-muted text-end mt-2 mt-sm-0">
                  <div>
                    <h5 class="font-size-16 mb-1">Devis n°</h5>
                    <p v-if="sellLoaded">{{ SellService.getNumber(sell.sellId, sell.created_date, sell.company) }}</p>
                    <p v-if="!sellLoaded" class="mb-1 placeholder-glow"><span class="placeholder col-2"></span></p>
                  </div>
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Date :</h5>
                    <p v-if="sellLoaded">{{ SellService.getDate(sell.created_date) }}</p>
                    <p v-if="!sellLoaded" class="mb-1 placeholder-glow"><span class="placeholder col-2"></span></p>
                  </div>
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Techniciens :</h5>
                    <p v-if="sellLoaded">{{ sell.workers }}</p>
                    <p v-if="!sellLoaded" class="mb-1 placeholder-glow"><span class="placeholder col-2"></span></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-2 mt-5">
              <div class="table-responsive">
                <table class="table table-nowrap table-centered mb-0 table-slim">
                  <thead>
                    <tr>
                      <th>Prestation</th>
                      <th>Description</th>
                      <th class="text-end">Prix unitaire HT</th>
                      <th class="text-end">Quantité</th>
                      <th class="text-end" style="width: 120px">Total HT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in sell.sellItems" :key="item.id">
                      <td>
                        <strong>{{ item.title }}</strong>
                      </td>
                      <td class="description">{{ item.description }}</td>
                      <td class="text-end">{{ item.price | price }}</td>
                      <td class="text-end">{{ item.quantity }}</td>
                      <td class="text-end">{{ item.total | price }}</td>
                      <td class="text-end">
                        <a href="javascript:void(0);" class="px-2 text-danger" content="Supprimer" v-tippy @click="onDeleteSellItem(item.id)">
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </td>
                    </tr>
                    <tr v-if="sellLoaded">
                      <td scope="row" colspan="4" class="text-end">
                        <strong>Total HT</strong>
                      </td>
                      <td class="text-end">{{ sell.total | price }}</td>
                    </tr>
                    <tr v-if="sellLoaded">
                      <td scope="row" colspan="4" class="text-end">
                        <strong>TVA</strong>
                      </td>
                      <td class="text-end">{{ sell.totalTaxes | price }}</td>
                    </tr>
                    <tr v-if="sellLoaded">
                      <td scope="row" colspan="4" class="text-end">
                        <strong>Total TTC</strong>
                      </td>
                      <td class="text-end">{{ sell.totalWithTaxes | price }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-center" v-if="!sellLoaded">
                    <b-spinner class="align-middle" variant="primary"></b-spinner>
                </div>
              </div>
              <div class="d-print-none mt-5">
                <div class="text-end">
                  <button v-b-modal.modal-add-item class="btn btn-primary w-md ms-1">
                    Ajouter une prestation
                    <i class="uil uil-plus ms-2"></i>
                  </button>
                  <AddSellItem @items-updated="onSellUpdated" :sell="sell" />
                </div>
              </div>

              <div class="row mt-2" v-if="sell.details && sell.details != ''">
                <div class="col-md-12">
                    <h5>Détails/Remarques</h5>
                    <p class="comment">{{ sell.details }}</p>
                </div>
              </div>

              <div class="row mt-5" v-if="sell.payment_method == 'Financement'">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered mb-0 boxed">
                      <thead>
                        <tr>
                          <th>Prix comptant</th>
                          <th>Versement initial</th>
                          <th>Montant du crédit</th>
                          <th>Mensualité sans assu.</th>
                          <th>Mensualité avec assu.</th>
                          <th>Coût total de l'achat à crédit</th>
                          <th>Durée</th>
                          <th>Taux débiteur fixe</th>
                          <th>TAEG</th>
                          <th>Nombre de mensualité</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><currency-input v-model="financialSection.price"></currency-input></td>
                          <td><currency-input v-model="financialSection.deposit"></currency-input></td>
                          <td><currency-input v-model="financialSection.credit"></currency-input></td>
                          <td><currency-input v-model="financialSection.monthly"></currency-input></td>
                          <td><currency-input v-model="financialSection.monthlyWithIns"></currency-input></td>
                          <td><currency-input v-model="financialSection.total"></currency-input></td>
                          <td><input class="form-control" type="text" v-model="financialSection.duration"></td>
                          <td><currency-input v-model="financialSection.rate"></currency-input></td>
                          <td><currency-input v-model="financialSection.taeg"></currency-input></td>
                          <td><input class="form-control" type="number" v-model="financialSection.months"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="d-print-none mt-4">
                <div class="float-end">
                  <button
                    @click="updateSell()"
                    class="btn btn-success"
                  >
                    Étape suivante <i class="fa fa-chevron-right ms-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
  import Swal from "sweetalert2";
  import Layout from "../layouts/main";
  import SellService from "../../services/sell.service";
  import Utilities from "../../services/utilities";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import api from '../../api/api';
  import AddSellItem from "@/forms/add-sellitem";
  import moment from 'moment';
  import SellImage from "@/forms/sell-image";

  /**
   * Invoice Detail component
   */
  export default {
    page: {
      title: "Devis",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ]
    },
    props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
    components: { 
      Layout, 
      PageHeader,
      AddSellItem,
      SellImage
    },
    data() {
      return {
        SellService,
        title: "Devis",
        items: [
          {
              text: "Accueil",
              to: "/"
          },
          {
            text: "Devis",
            active: true,
          },
        ],
        sell: {},
        sellLoaded: false,
        financialSection: {}
      };
    },
    mounted() {
      if (this.$route.params.id) {
        this.loadSell(this.$route.params.id);
        this.financialSection = {...SellService.financialSection};
      }
    },
    computed: {
      totalWithTaxes() {
        return (this.sell.totalWithTaxes) ? this.sell.totalWithTaxes : '';
      }
    },
    watch: {
      totalWithTaxes(val) {
        this.financialSection.price = val;
      }
    },
    methods: {
      loadSell(id) {
        this.sellLoaded = false;
        api
          .get('/sales/' + id)
          .then(
              (response) => {
                  if (response && response.status == 403) {
                    this.$router.push('/');
                  }

                  if (response && response.status == 200) {
                      this.sell = response.data;
                      this.sellLoaded = true;
                      if (this.sell.financialSection && Object.keys(this.sell.financialSection).length > 0) {
                        this.financialSection = {...this.sell.financialSection};
                      }
                  }
              },
              () => {
                  Utilities.errormsg();
                  this.sellLoaded = true;
              }
          )
      },
      onDeleteSellItem(id) {
        Swal.fire({
          title: "Attention",
          text: "Êtes-vous sûr de vouloir supprimer cette prestation ? Cette action est irréversible.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Supprimer",
          cancelButtonText: "Annuler",
        }).then((result) => {
          if (result.value) {
            api
              .delete('/sell_items/' + id)
              .then(
                  (response) => {
                      if (response && response.status == 204) {
                          Swal.fire({
                            title: "Succès",
                            text: "La prestation a bien été supprimée !",
                            timer: 1500,
                            icon: "success",
                            confirmButtonColor: "#2e58a6",
                            confirmButtonText: "Fermer",
                          });
                          this.onSellUpdated();
                      }
                  },
                  () => {
                      Utilities.errormsg();
                  }
              )
          }
        });
      },
      closeModal() {
          this.$bvModal.hide('modal-add-item');
      },
      onSellUpdated() {
          this.closeModal();
          this.loadSell(this.sell.id);
      },
      moment: function () {
        return moment();
      },
      sellValid() {
        this.errors = [];

        // if (this.sell.images.length < 1) {
        //     this.errors.push('Les photos sont obligatoires');
        // }

        if (this.sell.sellItems.length < 1) {
            this.errors.push('Ajouter au moins une prestation');
        }

        if (this.errors.length == 0) {
            return true;
        } else {
            Swal.fire("Erreur", this.errors[0], "error");
            return false;
        }
      },
      updateSell: function () {
        let sellToSend = {};
        sellToSend.financialSection = {...this.financialSection};

        if (this.sellValid()) {
          var that = this;
  
          api
              .patch('/sales/' + this.sell.id, sellToSend, {
                  headers: {
                      'Content-Type': 'application/merge-patch+json'
                  }
              })
              .then(
                  (response) => {
                      if (response && response.status == 200) {
                          let routeData = that.$router.resolve({path: '/sell/' + that.sell.id + '/worksheet'});
                          window.open(routeData.href, '_self');
                      }
                  },
                  () => {
                      Utilities.errormsg();
                  }
              )
        }
      }
    },
    middleware: "authentication",
  };
</script>

<style scoped lang="scss">
  .logo {
      width: 200px;
  }

  .dropdown-menu {
    min-width: 1rem !important;
  }

  .table-responsive {
    input, ::v-deep input {
      border: none;
      &:focus {
        outline: none !important;
      }
    }

    table.boxed {
      tr {
        vertical-align: top;
      }
      td {
        max-width: 100px;
      }
      input {
        max-width: 80px;
      }
    }
  }

  .comment, td.description {
    white-space: pre-wrap;
  }
</style>